<template>
  <div class="qaDetails">
    <div class="details_title">
      {{ details.title }}
    </div>
    <div class="details_content">
      <div class="html_text" v-html="details.content"></div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  data () {
    return {
      details: {
        id: "",
        title: "",
        content: "",
        web_view: ""
      },
    }
  },
  created () {
    this.$api.getQuestionDetail({ id: this.$route.query.id }).then(res => {
      this.details = res.data;
    })
  },
  methods: {
    ...mapMutations(['SET_QA_DETAILS_SHOW']),

  },
  destroyed () {
    this.SET_QA_DETAILS_SHOW(false);
  },
}
</script>

<style lang="scss">
.qaDetails {
  .details_title {
    font-size: 28px;
    font-weight: 500;
    color: #333333;
    padding: 0px 15px 15px;
    border-bottom: 1px solid #ebebeb;
  }
  .details_content {
    .html_text {
    }
  }
}
</style>